import React from 'react';

import { Manufacturers } from '../../components/views/settings/manufacturers/manufacturers'
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <Manufacturers Action={0} />
        </LayoutLoggedIn>
    );
}